@import url('https://fonts.googleapis.com/css?family=Abel|Signika');

$primary: #043758; /* MAIN COLOR */
$secondary: #041325; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #fff;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


html,body {
  height: 100%;


  /*margin: 0;
  padding: 0;
  overflow: auto;*/
}
.delay-1 {
  animation-delay: .25s;
}
.delay-2 {
  animation-delay: .5s;
}
.delay-3 {
  animation-delay: .75s;
}
.delay-4 {
  animation-delay: 1s;
}
body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  font-size: 1.7em;
  font-family: 'Abel', sans-serif;
  font-weight: normal;
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Signika', sans-serif;

  }
}

.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}

.navbarFixed {
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 999;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


nav.navbar {
	z-index: 1000;
	border: none;

	.navbar-nav {

	    > li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: $blk;

			@media (max-width: 767px) {
				  margin-top: 0;
				  padding: 6px 20px;
			    display: inline-block;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/
@mixin btn {
	border: none;
	background: $primary;
	color: $wht;
	padding:1em 2em;
	font-size: 1em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 1em 0em;
	transition: 0.7s ease all;
  text-decoration: none;
  @media(max-width: 767px){
    font-size: .7em;
  }

	&:hover, &:active, &:visited, &:focus {
    background: $primary;
  	color: $wht;
		text-decoration: none;

			@media (max-width: 1024px) {
        background: $primary;
        color: $wht;
			}
	}
}

.btn-default {
	@include btn;
}

// mixins end here


// sections start
section{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;
  @media(max-width: 991px){
      padding:60px 0;
  }
  @media(max-width: 767px){
      padding:40px 0;
  }

}
.section{
  padding: 450px 0 100px 0;
  color: $wht;
  background-image: url('../img/bg1.jpg');
  background-size: cover;
  @media(max-width: 991px){
    padding: 350px 0 50px 0;
  }
  @media(max-width: 767px){
    padding: 250px 0 50px 0;
  }
  @media(max-width:450px){
    padding:150px 0 50px 0;
  }
  h1 {
    @media(max-width: 991px){
      font-size: 1.7em;
    }
  }
}
.section-a {
  color: $wht;
  background-color: darken($primary, 5%);
  h2 {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.1em;
    @media(max-width: 991px){
      font-size: 1.4em;
    }
  }
  .box{
    padding: 2px;
  }

}
.section-b {
  background-color: darken($primary, 15%);
  background-repeat: repeat;
  background-size: auto;
  color: $wht;
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  padding: 150px 0;
  color: $wht;
  @media(max-width: 991px){
    padding: 120px 0;
  }
  @media(max-width: 767px){
    padding: 80px 0;
  }
  h2 {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.1em;
    @media(max-width: 991px){
      font-size: 1.5em;
    }
    @media(max-width: 767px){
      font-size: 1em;
    }
  }

}
.section-c{
  background-color: darken($primary,10%);
  color: $wht;
  h2 {
    margin-top:20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.1em;
  }
  img{
    margin-bottom: 15px;
  }
  .box {
    padding: 2px;
    margin-top: 30px;
  }
}
.section-d {
  color: $wht;
  background-color: darken($primary,15%);
  font-size: 1.1em;
  h1 {
    font-size: 5em;
    @media(max-width: 767px){
      font-size: 3em;
    }
  }
  h3{
    text-transform: uppercase;
    color: $wht;
    font-size: 1em;
    font-weight: bold;
  }
  @media(max-width: 480px){
    text-align: center;
    font-size: 1em;
  }

  i{
    color: $wht;

    @media(max-width: 480px){
      color: $wht;
      width: 100%;
      text-align: center;
    }
  }

  .footer-banner {
    border-left: 10pxsolid $blk;
    border-right: 10px solid $blk;
  }


  ul {
    padding: 0;

    li {
      line-height: 2em;
      list-style: none;
      @media(max-width: 480px){
        text-align: center;
      }
      i{
        padding-right: 20px;
      }

      a{
        color: $wht;
        text-decoration: none;
      }
    }
  }
}
// sections end


.modal-dialog {
	max-width: 500px;
	width: 100%;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: darken($primary, 20%);
	color: $footerLinks;
	font-size: 15px;
  background-repeat: repeat;
  background-size: auto;

	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}
select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}
a,a:hover,a:focus,a:active {
  outline: 0 !important;
}
.logo {
	max-height:100px;
	padding: 0.5em;
}
@media(max-width: 767px) {
	.logo {
		max-height:70px;
	}

    .navbar-header a {
        float: left;
    }
}


.top-pad {
	padding: 3em;
	background: white;
  font-size: 1.2em;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 1024px) {
		display: block;  /* may need to be inline-block */
	}
}
